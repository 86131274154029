import React from 'react';
import SignUp from "./pages/Login/signUp";
import SignIn from "./pages/Login/signIn";
import Trash from "./pages/Trash/trash";
import ForgotPassword from './pages/Login/forgotPassword'
import Resetpassword from './pages/Login/resetPassword'
import Users from './pages/Users'
import { Navigate } from 'react-router-dom';
import Favourite from './pages/Favourites';
import Profile from './pages/Profile';

import Settings from './pages/Home/index';
import Folders from './pages/Folders/folders';
const routes = [
    {
        children: [
            { path: '/home', element: <Settings /> },
            { path: '/favourites', element: <Favourite /> },
            { path: '/trash', element: <Trash /> },
            { path: '/users', element: <Users /> },
            { path: '/folders', element: <Folders /> },
            { path: '/profile', element: <Profile /> },
        ]   
    },
    { path: '/signin', element: <SignIn /> },
    { path: '/signup', element: <SignUp /> },
    // { path: '/landingpage', element: <LandingPage /> },

    // { path: '/404', element: <NotFound /> },
    { path: '/forgotpassword', element: <ForgotPassword /> },
    { path: '/forgotworkspace', element: <ForgotPassword /> },
    { path: '/resetpassword', element: <Resetpassword /> },
    { path: '/', element: <Navigate to="/signin" /> }, 
    { path: '/*', element: <Navigate to="/404"  replace/> }, 

    // <Route component={LandingPage} />
]





export default routes;

