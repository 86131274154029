import React from "react";
import DataTable from "react-data-table-component";
import { Box, Grid, Typography,Tooltip,Stack,IconButton } from "@mui/material";
import Folder from "./../../images/files/tableFile.jsx";
import SortIcon from "./../../images/sortIcon.svg";
import { FileType } from "../../components/Function";
import UndoFavourite from './../../images/undo.svg'
import moment from "moment";

const FavouriteTable = (props) => {
  const fileType = (type) => {
    return FileType.getFileType(type)
  };

const viewColumns = [
    {
      name: "Title",
      selector: (row) => row.restore_path,
      sortable: true,
      cell: (row, column) => {
        const isSelected = "props.selectedItems.some((r) => r === row.path)";
        return (
          <Grid
            display="flex"
            alignItems="center"
            sx={{  whiteSpace: "wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordBreak:'break-word' }}
          >
            <Box 
            sx={{ background:  props?.theme ==='default' ?(isSelected ? '#fff' : '#F6EAE6') :(isSelected ? '#fff' :  "#C9F0FC"),
              display: "flex",
              alignItems: "center",
              justifyContent: "center", minWidth:40,height:40, borderRadius: 1 }}
              >
             { row.type === "folder"  ?<Folder theme={props.theme}/>: <img
                src={fileType(row.path)}
                alt=""
                width={25}
              />}
            </Box>
            <Tooltip title={row.name}>
            <Typography variant="settingLable" ml={1} >
              {row.name}
            </Typography>
            </Tooltip>
          </Grid>
        );
      },
    },
   
    {
      name: "Content Type",
      sortable: true,
      selector: (row) => row.type?.toUpperCase(),
    },
    {
      name: "Path",
      // sortable: true,
      selector: (row) => row.path,
    },
    {
        name: "Last Modified",
        sortable: true,
        selector: (row) => row.created_at ? moment(row.created_at).format('ll') : 'N/A',
      },
    {
      name: "Action",
      right: true,
      cell: (row) => {
        return (
           (
           <Stack direction={'row'}>
           <Tooltip title="Undo Favourite">
           <IconButton disableRipple sx={{padding:'0 10px'}}
           onClick={()=> {
            props.deleteFavourites(row.id)
          }} >
            <img src={UndoFavourite}  alt=""/>
            </IconButton>
           </Tooltip>
         
           </Stack>
          )
        );
      },
    },
  ];


 
  const handleChange = (e) => {
    const newArray = e.selectedRows.map((item) => item.id);
    props.setSelectedItems(newArray);
  };



  const caseInsensitiveSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
     const aField = selector(rowA)?.toLowerCase();
     const bField = selector(rowB)?.toLowerCase();
     let comparison = 0;
     if (aField > bField) {
      comparison = 1;
     } else if (aField < bField) {
      comparison = -1;
     }
     return direction === 'desc' ? comparison * -1 : comparison;
    });
   };

  return (
    <Box sx={{ m: 1 ,border:props?.data.length>0 &&'1px solid lightGrey', borderRadius:'8px'}}>
      <DataTable
        dense
        noDataComponent="No data available"
        pagination
        noBorder
        sortIcon={
          <img style={{ margin: "0px 0 -2px 6px " }} src={SortIcon} alt="" />
        }
        selectableRowsHighlight
        selectableRows={props.action}
        columns={viewColumns}
        sortFunction={caseInsensitiveSort}
        data={props?.data}
        clearSelectedRows={!props.action}
        onSelectedRowsChange={handleChange}
        highlightOnHover
        // selectableRowsComponent={BootyCheckbox}
        // selectableRowsComponentProps={selectProps}
      />
      
    </Box>
  );
};

export default FavouriteTable;
