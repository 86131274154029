import React from 'react'
// import Header from './Header';
import Toolbar from './Toolbar';
import Header from './Header';

const Sidebar = (props) => {
  return (
    <>

      <Toolbar />
      <div style={{ backgroundColor: '#EEF4FC', height: '100vh', position: 'relative', paddingTop:'1rem' }}>
        <div style={{ height: 'auto', position: 'absolute', top: '0px',bottom:'0px', left: '240px', borderRadius: '24px ', width: '-webkit-fill-available', margin: '1rem', backgroundColor: '#fff' }}>
           <Header />
          {props.children} 
        </div>
      </div>
    </>

  )
}

export default Sidebar