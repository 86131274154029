
import axios from "axios";
import { helpers } from "../_helpers";
import { crypto } from "../components/Security/crypto";
const settings = require('../config');
const ApiEndpoint = settings.ApiEndPoint;

const apiEndpoint = ApiEndpoint;

export const apiData = {
  createFolder,
  getBucketList,
  getDirectoryDetails,
  getObjectDetails,
  deleteObject,
  renameObject,
  moveObject,
  copyObject,
  getDirectoryList,
  uploadObject,
  getTrashList,
  getFavouriteList,
  addFavorite,
  deleteFavourites,
  moveToTrash,
  restoreItem,
  uploadFiles,
  uploadtoS3,
  LockObject,
  shareLink,
  addBucket,
  getAwsRegionList,
  getZureRegionList,
  archiveFiles,
  unzipFile
};





async function createFolder(folderDetails, id) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const encryptedData = crypto.encryption(folderDetails)
  try {
    const data = await axios.post(
      `${apiEndpoint}/create_folder?setting_id=${id} `,
      encryptedData,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}

async function getBucketList(group) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const data = await axios.get(`${apiEndpoint}/settings?group=${group}`, requestOptions);
    return data;
  } catch (error) {
    return error.response;
  }
}

async function getDirectoryDetails(settingId,fileName) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const data = await axios.get(`${apiEndpoint}/directory_info?setting_id=${settingId}&prefix=${fileName}`, requestOptions);
    return data;
  } catch (error) {
    return error.response;
  }
}

async function getObjectDetails(settingId,fileName,password) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "password":password
    },
  };
  try {
    const data = await axios.get(`${apiEndpoint}/object_info?setting_id=${settingId}&prefix=${fileName}`, requestOptions);
    return data;
  } catch (error) {
    return error.response;
  }
}




async function deleteObject(objectDetails) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const encryptedData = crypto.encryption(objectDetails)
  try {
    const data = await axios.post(
      `${apiEndpoint}/delete_object`,
      encryptedData,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}

async function renameObject(objectDetails) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const encryptedData = crypto.encryption(objectDetails)
  try {
    const data = await axios.post(
      `${apiEndpoint}/rename_object`,
      encryptedData,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}

async function moveObject(objectDetails) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const encryptedData= crypto.encryption(objectDetails)
  try {
    const data = await axios.post(
      `${apiEndpoint}/move_object`,
      encryptedData,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}

async function copyObject(objectDetails) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const data = await axios.post(
      `${apiEndpoint}/copy_object`,
      objectDetails,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}

async function uploadObject(objectDetails) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const encryptedData = crypto.encryption(objectDetails)
  try {
    const data = await axios.post(
      `${apiEndpoint}/upload_object?setting_id=13`,
      encryptedData,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}



async function getDirectoryList(fileName,settingId, password) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "password":password
    },
  };
  try {
    const data = await axios.get(`${apiEndpoint}/list_directory?prefix=${fileName}&setting_id=${settingId}`, requestOptions);
    return data;
  } catch (error) {
    return error.response;
  }
}

async function getFavouriteList(settingId) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const data = await axios.get(`${apiEndpoint}/favorites?setting_id=${settingId}`, requestOptions);
    return data;
  } catch (error) {
    return error.response;
  }
}

async function addFavorite(payload) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const encryptedData = crypto.encryption(payload)
  try {
    const data = await axios.post(
      `${apiEndpoint}/favorites`,
      encryptedData,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}

async function deleteFavourites(id) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
    // data: favouriteIds,
  };
  try {
    const data = await axios.delete(
      `${apiEndpoint}/favorites/${id}`,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}

async function getTrashList(id) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const data = await axios.get(`${apiEndpoint}/trash?setting_id=${id}`, requestOptions);
    return data;
  } catch (error) {
    return error.response;
  }
}

async function moveToTrash(payload) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const data = await axios.post(
      `${apiEndpoint}/trash`,
      payload,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}

async function restoreItem(id) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const data = await axios.delete(
      `${apiEndpoint}/trash/${id}`,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}

async function uploadFiles(objectDetails,settingId,url) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const encryptedData = crypto.encryption(objectDetails)
  try {
    const data = await axios.post(`${apiEndpoint}/${url}?setting_id=${settingId}`,encryptedData, requestOptions);
    return data;
  } catch (error) {
    return error.response;
  }
}


async function uploadtoS3(url,file) {
  delete axios.headers.common['Authorization'];
  try {
    const data = await axios.put(url,file);
    return data;
  } catch (error) {
    return error.response;
  }
}


async function getAwsRegionList() {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const data = await axios.get(`${apiEndpoint}/aws_regions`, requestOptions);
    const response = crypto.decryption(data.data.data)
    return {...data,data:response};
  } catch (error) {
    return error.response;
  }
}

async function getZureRegionList() {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const data = await axios.get(`${apiEndpoint}/azure_regions`, requestOptions);
    return data;
  } catch (error) {
    return error.response;
  }
}


async function LockObject(objectDetails,parameter) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const encryptedData = crypto.encryption(objectDetails)
  try {
    const data = await axios.post(
      `${apiEndpoint}/${parameter}`,
      encryptedData,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}


async function shareLink(objectDetails) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const encryptedData = crypto.encryption(objectDetails)
  try {
    const data = await axios.post(
      `${apiEndpoint}/share`,
      encryptedData,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}



async function addBucket(payload) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const encryptedData = crypto.encryption(payload)
  try {
    const data = await axios.post(
      `${apiEndpoint}/settings`,
      encryptedData,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}


async function archiveFiles(payload) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const encryptedData = crypto.encryption(payload)
  try {
    const data = await axios.post(
      `${apiEndpoint}/archive`,
      encryptedData,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}

async function unzipFile(payload) {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const encryptedData = crypto.encryption(payload)
  try {
    const data = await axios.post(
      `${apiEndpoint}/un_archive`,
      encryptedData,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}

