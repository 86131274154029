import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Box, Grid, Typography, Tooltip } from "@mui/material";
import Folder from "./../../images/files/tableFile.jsx";
import SortIcon from "./../../images/sortIcon.svg";
import Menu from "../../components/Card/Menu.jsx";
import moment from "moment";
import CustomStyles from "../../components/Table/index.js";
import { FileType } from "../../components/Function/index.jsx";
import Lock from './../../images/lockIcon.svg'
import CreateDialog from "../../components/Dialog/CreateDialog";
import CreateUser from "./CreateFolder.jsx";
import Share from "./Share.jsx";

const TableComponent = (props) => {
  const [open, setOpen] = useState(false); 
  const [currentRow, setCurrentRow] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [clickCount, setClickCount] = useState(0);
  const [doubleClickTimer, setDoubleClickTimer] = useState(null);

  const customStyles = CustomStyles;

  const fileType = (type) => {

    return FileType.getFileType(type);

  };

  const handleDoubleClick = (row, password) => {
    clearTimeout(doubleClickTimer);
    setClickCount(0);
    if (row.type === "folder") {
      props.setDirectory(true);
      props.setClick(false);
      props.setFolderPath(row?.prefix);
      props.getDirectoryList(row?.prefix,'',password);
    } else {
      props.setClick(false);
      props.setOpenPreview(true);
      props.setActiveObject({
        prefix: row.prefix,
        title: row.name,
        password:password
      });
    }
  };

  const handleSingleClick = (row) => {
    setClickCount(clickCount + 1);
    // Set a timeout for double-click
    if (clickCount === 0) {
      setDoubleClickTimer(
        setTimeout(() => {
          setCurrentRow(row);
          {
            props.setDirectory(false);
            // if (props.bucket) {
            //   cookies.set("bucketName", row.Name[0]);
            //   props.setBucket(false);
            // } else {
              let time = row.type === "folder" ? 200 : 0;
              setTimeout(() => {
                !props.action && props.setPrefix(row?.prefix);
              }, [time]);
            // }
          }
          setClickCount(0);
        }, 500)
      ); // Adjust the timeout as needed
    } else {
      clearTimeout(doubleClickTimer);
      setClickCount(0);
    }
  };

  const onRowClicked = (row) => {
    handleSingleClick(row);
  };

  const unlockFile =((values)=>{
    console.log(values)
    handleDoubleClick(currentRow,values.title)
  })

  const onRowDoubleClicked = (row) => {
    if (row.lock) {
      setCurrentRow(row);
      setOpen(true);
      setDialogTitle(row.name);
    } else {
      handleDoubleClick(row);
    }
  };
  const [lastClickTime, setLastClickTime] = useState(0);

  const handleClick = (row) => {
    const currentTime = Date.now();
    if (currentTime - lastClickTime < 300 && !props.bucket) {
      onRowDoubleClicked(row);
    } else {
      handleSingleClick(row);
      setLastClickTime(currentTime);
    }
  };

  const viewColumns = [
    {
      name: "File Name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row, column) => {
        const isSelected = props.selectedItems.some((r) => r === row.id);
        return (
          <Grid
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            onClick={() => handleClick(row)}
          >
            <Box
              sx={{
                background:
                  props?.theme === "default"
                    ? isSelected
                      ? "#fff"
                      : "#F6EAE6"
                    : isSelected
                    ? "#fff"
                    : "#C9F0FC",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: 20,
                height: 20,
                borderRadius: 1,
              }}
            >
              {row.type === "folder" ? (
                <Folder theme={props.theme} />
              ) : (
                <img src={fileType(row?.prefix)} alt="" width={25} />
              )}
            </Box>
            <Tooltip title={row.name}>
              <Typography
                variant="settingLable"
                ml={1}
                sx={{color:row.lock ? 'grey' : ''}}
              >
               {row.name}
               {/* { row.lock  && <img style={{paddingRight:'4px'}} src={Lock} alt="lock"/>} */}
              </Typography>
            </Tooltip>
          </Grid>
        );
      },
    },

    {
      name: "File Size",
      selector: (row) => (row.size ? row.size : "N/A"),
      sortable: true,
    },
    {
      name: "Content Type",
      sortable: true,
      selector: (row) => row.type?.toUpperCase(),
    },
    {
      name: "Last Modified",
      sortable: true,
      selector: (row) => (row.date ? moment(row.date).format("ll") : "N/A"),
    },
    {
      name: "Lock Status",
      // sortable: true,
      selector: (row) => row.lock,
      cell: (row, column) => {
        return (
            row.lock && <img src={Lock} width={16} alt=""/>
        )}
    },
    {
      name: "",
      right: true,
      cell: (row) => {
        return (
          row.prefix && (
            <Box>
              <Menu
                data={row}
                setLock={props.setLock}
                disabled={props.action}
                setEditorMode={props.setEditorMode}
                setCurrentRow={setCurrentRow}
                setOpenDialog={setOpen}
                setDialogTitle={setDialogTitle}
                location={row?.prefix}
                name={row?.name}
                setItem={props.setItem}
                setPaste={props.setPaste}
                setOpenPreview={() => {
                  props.setOpenPreview(true);
                  props.setActiveObject({
                    prefix: row.prefix,
                    title: row.name,
                  });
                }}
                handleDelete={() => props.handleDelete(row?.prefix)}
              />
            </Box>
          )
        );
      },
    },
  ];

  const caseInsensitiveSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      const aField = selector(rowA)?.toLowerCase();
      const bField = selector(rowB)?.toLowerCase();
      let comparison = 0;
      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }
      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  const handleChange = (e) => {
    const newArray = e.selectedRows.map((item) => item.prefix);
    props.setSelectedItems(newArray);
  };

  const conditionalRowStyles = [
    {
      when: (row) =>
        row.prefix === props.previewdata?.Location ||
        row?.prefix === props.paste?.location ||
        row?.prefix === currentRow.prefix,
      style: {
        backgroundColor:  "#e3f2fd" ,
      },
    },
  ];
  const rowDisabledCriteria = (row) => row.lock;
  return (
    <Box sx={{ m: 1 ,border:props?.data.length>0 && '1px solid lightGrey', borderRadius:'8px'}}>
      <DataTable
        dense
        noDataComponent="No data available"
        pagination
        noBorder
        sortIcon={
          <img style={{ margin: "0px 0 -2px 6px " }} src={SortIcon} alt="" />
        }
        onRowClicked={onRowClicked}
        onRowDoubleClicked={onRowDoubleClicked}
        columns={viewColumns}
        selectableRows={props.action}
        data={props?.data}
        selectableRowDisabled={rowDisabledCriteria}
        sortFunction={caseInsensitiveSort}
        selectableRowsHighlight
        conditionalRowStyles={conditionalRowStyles}
        clearSelectedRows={!props.action}
        // selectableRowsComponent={BootyCheckbox}
        onSelectedRowsChange={handleChange}
        customStyles={customStyles}
        highlightOnHover
      />
        <CreateDialog
        theme={props.theme}
        open={open}
        setLock={props.setLock}
        fileOpen={dialogTitle ==="Rename a file" || dialogTitle ==="Share" ? false: true}
        title={dialogTitle ? dialogTitle : "Rename a file"}
        handleClose={setOpen}
        children={dialogTitle ==="Rename a file" ?
        <CreateUser
            close={setOpen}
            dialogTitle={'rename'}
            data={{ old_name: currentRow.name, location: currentRow.prefix,type:currentRow.type}}
            formSubmit={props.handleRename}
          /> :dialogTitle ==="Share" ?
          <Share
            close={setOpen}
            data={ currentRow }
            // formSubmit={props.share}
          />  :
          <CreateUser
        fileOpen={true}
          close={setOpen}
          dialogTitle={dialogTitle}
          formSubmit={unlockFile}
        />
         
        }
      />
    </Box>
  );
};

export default TableComponent;
