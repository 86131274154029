import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Typography,
  TextField,
  Box,
  FormControl,
  FormHelperText,
  Grid,
} from "@mui/material";
import AWS from "./../../images/aws.png";
import AZURE from "./../../images/azure.png";
import GCS from "./../../images/gcp.webp";
import { Button as MUIButton } from "@mui/material";
import { MUI } from "../../components/CustomComponents";
import { Formik } from "formik";
import * as Yup from "yup";
import { apiData } from "../../_services/home";
import { crypto } from "../../components/Security/crypto";

const PREFIX = 'AddBuketDialog';

const classes = {
  bucketGrid: `${PREFIX}-bucketGrid`
};

const Root = styled('div')(() => ({
  [`& .${classes.bucketGrid}`]: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EEEDEB",
      borderRadius: "10px",
    },
  }
}));

const AddBuketDialog = (props) => {

  const [bucketType, setBucketType] = useState(false);
  const [awsRegion, setAwsRegion] = useState([]);
  const [azureRegion, setAzureRegion] = useState(["eastus"]);

  const [initialValues, setInitialValues] = useState({
    cloudId: "",
    bucketName: "",
    accessKey: "",
    secretKey: "",
    accountName: "",
    region: "ap-south-1",
    gcpKey: "",
    connectionString: "",
  });

  const { CustomTypography, CustomInput, CustomSelect } = MUI;

  const BucketNames = [
    {
      name: "AWS",
      icon: AWS,
      id: 1,
    },
    {
      name: "GCS",
      icon: GCS,
      id: 2,
    },
    {
      name: "AZURE",
      icon: AZURE,
      id: 3,
    },
  ];

  const renderInputField = (
    label,
    name,
    value,
    placeholder,
    handleChange,
    touched,
    error,
    type
  ) => {
    return (
      <FormControl variant="standard" sx={{ marginTop: "4px" }} fullWidth>
        <CustomTypography>{label}</CustomTypography>
        <CustomInput
          error={Boolean(touched && error)}
          name={name}
          multiline={name === "gcpKey" || name === "connectionString"}
          rows={name === "gcpKey" ? 10 : name === "connectionString" ? 4 : 1}
          value={value}
          type={type ? type : "text"}
          onChange={handleChange}
          placeholder={placeholder}
          size="small"
        />
        <FormHelperText error sx={{ fontFamily: "Inter" }}>
          {touched && error}
        </FormHelperText>
      </FormControl>
    );
  };

  useEffect(() => {
    apiData.getAwsRegionList().then(async (res) => {
      if (res.status === 200) {
        try {
          const decreptedData = await crypto.decryption(res?.data?.data);
          setAwsRegion(decreptedData);
        } catch (error) {
          
        }
      }
    });
    apiData.getZureRegionList().then(async (res) => {
      if (res.status === 200) {
        try {
          const decreptedData = await crypto.decryption(res?.data?.data);
          setAzureRegion(decreptedData);
        } catch (error) {
          
        }
      }
    });
  }, []);
  return (
    <Root>
      {!bucketType ? (
        <Box>
          <Grid container>
            {BucketNames.map((bucket, index) => {
              return (
                <Grid
                  onClick={() => {
                    setBucketType(bucket);
                    setInitialValues({
                      ...initialValues,
                      cloudId: bucket.id,
                      region: bucket.id === 3 ? "eastus" : "ap-south-1",
                    });
                  }}
                  item
                  xs={4}
                  key={index}
                  textAlign={"center"}
                  px={2}
                  className={classes.bucketGrid}
                >
                  <img
                    src={bucket.icon}
                    width={100}
                    style={{ aspectRatio: "3/2", objectFit: "contain" }}
                    alt=""
                  />
                  <Typography sx={{ fontFamily: "Inter", fontWeight: 600 }}>
                    {bucket.name}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ) : (
        <Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              accessKey: Yup.string().when("cloudId", {
                is: 1,
                then: () => Yup.string().required("Please enter access key"),
                otherwise: () => Yup.string().nullable(),
              }),
              secretKey: Yup.string().when("cloudId", {
                is: 1,
                then: () => Yup.string().required("Please enter secret key"),
                otherwise: () => Yup.string().nullable(),
              }),
              gcpKey: Yup.string().when("cloudId", {
                is: 2,
                then: () => Yup.string().required("Json is Required"),
                otherwise: () => Yup.string().nullable(),
              }),
              connectionString: Yup.string().when("cloudId", {
                is: 3,
                then: () =>
                  Yup.string().required("Connection String is required"),
                otherwise: () => Yup.string().nullable(),
              }),
              accountName: Yup.string().required("Please enter account name"),
              bucketName: Yup.string().required("Please enter bucket name"),
              region: Yup.string().when("cloudId", {
                is: (values) => values === 1 || values === 3,
                then: () => Yup.string().required("Region is Required"),
                otherwise: () => Yup.string().nullable(),
              }),
            })}
            onSubmit={(values) => {
              // handleOnSubmit(values);
              props.handleSubmit(values);
            }}
          >
            {({
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Box textAlign={"center"}>
                  <img
                    src={bucketType.icon}
                    width={100}
                    style={{ aspectRatio: "3/2", objectFit: "contain" }}
                    alt=""
                  />
                  <Typography sx={{ fontFamily: "Inter", fontWeight: 600 }}>
                    {bucketType.name}
                  </Typography>
                </Box>
                {renderInputField(
                  "Account Name*",
                  "accountName",
                  values.accountName,
                  "Enter your account name",
                  handleChange,
                  touched.accountName,
                  errors.accountName
                )}
                {renderInputField(
                  "Bucket Name*",
                  "bucketName",
                  values.bucketName,
                  "Enter your bucket name",
                  handleChange,
                  touched.bucketName,
                  errors.bucketName
                )}
                {values.cloudId === 1 ? (
                  <>
                    {renderInputField(
                      "Access Key*",
                      "accessKey",
                      values.accessKey,
                      "Enter your access key",
                      handleChange,
                      touched.accessKey,
                      errors.accessKey
                    )}
                    {renderInputField(
                      "Secret Key*",
                      "secretKey",
                      values.secretKey,
                      "Enter your secret key",
                      handleChange,
                      touched.secretKey,
                      errors.secretKey
                    )}
                  </>
                ) : values.cloudId === 2 ? (
                  renderInputField(
                    "GCS JSON*",
                    "gcpKey",
                    values.gcpKey,
                    "Enter GCP JSON",
                    handleChange,
                    touched.gcpKey,
                    errors.gcpKey
                  )
                ) : (
                  renderInputField(
                    "Connection String*",
                    "connectionString",
                    values.connectionString,
                    "Enter connection string",
                    handleChange,
                    touched.connectionString,
                    errors.connectionString
                  )
                )}

                {values.cloudId !== 2 ? (
                  <FormControl
                    variant="standard"
                    sx={{ marginTop: "4px" }}
                    fullWidth
                  >
                    <CustomTypography>Select Region</CustomTypography>
                    <CustomSelect
                      disableClearable
                      id="grouped-demo"
                      size="small"
                      name="region"
                      value={values.region}
                      options={values.cloudId === 1 ? awsRegion : azureRegion}
                      onChange={(e, value) => {
                        setFieldValue("region", value);
                      }}
                      // getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          name="region"
                          fullWidth
                          {...params}
                          label=""
                        />
                      )}
                    />
                    <FormHelperText error sx={{ fontFamily: "Inter" }}>
                      {touched.region && errors.region}
                    </FormHelperText>
                  </FormControl>
                ) : null}
                <Box sx={{ float: "right" }}>
                  <MUIButton
                    color="primary"
                    type="submit"
                    disabled={props.loading}
                    sx={{ marginTop: "1rem", textAlign: "center" }}
                    variant=""
                  >
                    Add
                  </MUIButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      )}
    </Root>
  );
};

export default AddBuketDialog;
