import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TableComponent from "./favouriteTable";
import { Box, Button ,Alert, Typography} from "@mui/material";
import { apiData } from "../../_services/home";
import DialogInfo from "./../../images/home/dialogInfo.svg";
import { useSelector } from "react-redux";
import Alerts from "../../components/alert.jsx";
import Sidebar from "../../components/Toolbar/Sidebar.jsx";
import { crypto } from "../../components/Security/crypto.js";
import CreateDialog from "../../components/Dialog/CreateDialog.jsx";
import SessionExpired from "../../components/Dialog/SessionExpired.jsx";

const FrameParent = styled.div`
  position: absolute;
  top: 100px;
  width: 100%;
  height: 827px;
  overflow: hidden;
  color: var(--text-colors-text-secondary);
`;
const Favourite = () => {
  const [action,setAction]  = useState(false);
  const [data, setData] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [toastAlert, setToastAlert] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [selectedItems,setSelectedItems] = useState([]);
  const state=useSelector((state)=> state.authReducer)
  const [dialogTitle, setDialogTitle] = useState(false);

  const GetFavouriteList = () => {
    apiData.getFavouriteList(state.settingId).then((res) => {
      if (res.status === 200) {
        const decreptedData = crypto.decryption(res?.data?.data)
        setData(decreptedData);
      } else if (res.status === 401) {
        setDialogTitle("Important Notice")
        setSessionExpired(true)
      }else if(res.status === 404){
        setData([]);
        setToastMessage("No favourites found");
        setSeverity("success");
        setToastAlert(true);
      }else {
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message);
      }
    });
  };

  useEffect(()=>{
    GetFavouriteList()
  },[])

  const deleteFavourites =((id)=>{
    // const data={
    // favourite_ids:selectedItems.length>=1 ? selectedItems :[id]
    // }
    apiData.deleteFavourites(id).then((res) => {
      setAction(false)
      if (res.status ===200) {
        setSeverity("success");
        setToastAlert(true);
        setToastMessage("Removed from favourite list");
        GetFavouriteList()  
      } else if (res.status === 401) {
        setDialogTitle("Important Notice")
        setSessionExpired(true)
      }else {
        // setLoading(false);
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    })
  })
  
  return (
    <Sidebar>
      <FrameParent>
        <Box display={"flex"} justifyContent={"end"} mr={3}>
        </Box>
        <TableComponent
          data={data}
          deleteFavourites={deleteFavourites}
          action={action}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          GetFavouriteList={GetFavouriteList}
        />
      </FrameParent>
        {action && (
                <Alert
                  variant="alertToast"
                  action={
                    <Box display="flex">
                      <Button
                        color="inherit"
                        variant="save"
                        sx={{ mr: "65px", p: 1, textTransform: "capitalize" }}
                        onClick={() => deleteFavourites()}
                      >
                        { `Remove(${selectedItems.length})`}
                      </Button>
                      <Button
                        color="inherit"
                        size="small"
                        variant="cancel"
                        sx={{ p: 1, mr: 5 }}
                        onClick={() => {
                          setSelectedItems([]);
                          setAction(false);
                        }}
                      >
                        Cancel
                      </Button>
         
                    </Box>}
                     icon={<img src={DialogInfo} alt="info" />}
                     >
                    
                      <Typography variant="alertText">Select the item that you want to remove from favourite list
                  </Typography>
              
                </Alert>
              )}
          <Alerts
        severity={severity}
        handleClose={() => setToastAlert(false)}
        message={toastMessage}
        open={toastAlert}
      />
        <CreateDialog
            open={sessionExpired}
            title={dialogTitle}
            children={<SessionExpired/>}
          />
    </Sidebar>
  );
};

export default Favourite;
