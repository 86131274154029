import {
  Box,
  Container,
  FormControl,
  Card,
  FormHelperText,
} from "@mui/material";
import React, { useState} from "react";
import Logo from "./../../images/home/logo.png";
import { MUI } from "../../components/CustomComponents";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../../_services/login";
import Alert from "../../components/alert";
import { useLocation } from "react-router-dom";
import { crypto } from "../../components/Security/crypto";
const SignIn = () => {
  const { CustomTypography, CustomInput, LoginButton } = MUI;
  const [severity,setSeverity]= useState(false)
  const [message,setMessage]= useState(false)
  const [open,setOpen]= useState(false)
  const location = useLocation()
  const navigate = useNavigate();

  console.log(location)
  const renderInputField = (
    label,
    name,
    value,
    placeholder,
    handleChange,
    touched,
    error,
    type
  ) => {
    return (
      <FormControl variant="standard" sx={{ marginTop: "4px" }} fullWidth>
        <CustomTypography>{label}</CustomTypography>
        <CustomInput
          error={Boolean(touched && error)}
          name={name}
          value={value}
          type={type ? type : "text"}
          onChange={handleChange}
          placeholder={placeholder}
          size="small"
        />
        <FormHelperText error sx={{ fontFamily: "Inter" }}>
          {touched && error}
        </FormHelperText>
      </FormControl>
    );
  };

  const renderTitle = (title, description) => {
    return (
      <Box
        alignItems={"center"}
        mb={3}
        display={"flex"}
        flexDirection={"column"}
      >
        <img
          style={{ marginBottom: "1rem" }}
          src={Logo}
          width={300}
          alt="logo"
        />
        <CustomTypography
          variant="h6"
          sx={{ color: "#101828", fontSize: "30px" }}
        >
          {title}
        </CustomTypography>
        <CustomTypography sx={{ color: "#475467" }}>
          {description}
        </CustomTypography>
      </Box>
    );
  };

  const handleOnSubmit =async (values) => {
    const passwordData = {
      "email": values.email,
      "workspace_name": values.workspace
    }
    const workspaceData =   {
      "email": values.email,
    }
    const data = location.state?.workspace ? crypto.encryption(workspaceData) : crypto.encryption(passwordData)    
    const credential = location.state?.workspace ? 'forgot_workspace' :'users/forgot-password'
    try{
     await loginApi.forgotCredentials(data,credential).then((res) => {
        console.log(res);
        if (res.status ===200 && res?.data?.message === "Please check your email for reset password") {
          setOpen(true);
          setSeverity('success')
          setMessage('Check your email to reset passsword')
          setTimeout(()=>{
            navigate('/resetpassword',{state:{email:values.email}})
          },[3000])
        } else if (res.status === 200 && res?.data?.message === "Kindly check your email. Thank you.") {
          setOpen(true);
          setSeverity('success')
          setMessage('Check your email for workspace')
          setTimeout(()=>{
            navigate('/signin')
          },[3000])
        } else{
          setOpen(true)
          setSeverity('error')
          setMessage(res.data.message)
        }
      });
    }catch{
      setSeverity('error')
      setMessage('Something went wrong')
      setOpen(true)
    }
  };


  const forgotPasswordSchema ={ 
      email: Yup.string()
        .required("Please enter email")
        .email("Please enter a valid email"),
        workspace:Yup.string()
        .required("Please enter workspace name")
  }
  const forgotWorkspaceSchema ={
      email: Yup.string()
        .required("Please enter email")
        .email("Please enter a valid email"),
  }

  const validationSchema = location.state?.workspace ? forgotWorkspaceSchema : forgotPasswordSchema

  return (
    <Box sx={{background:'#EEF4FC'}}>
    <Container
      maxWidth="sm"
      sx={{
        height: "100vh",
        // margin:'auto auto',
        alignItems: "center",
        display: "flex",
      }}
    >
      <Card
        sx={{
          width: "100%",
          boxShadow: "1px 1px 10px 0px lightGrey",
          height: "80vh",
          paddingTop: "40px",
        }}
      >
        <Formik
          initialValues={{
            email: "",
            workspace:""
          }}
          validationSchema={ Yup.object().shape(validationSchema)}
          onSubmit={(values) => {
            handleOnSubmit(values);
          }}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Container maxWidth={"xs"} >
                {renderTitle("",  `Forgot ${location.state?.workspace ?"Workspace" : "Password"} `)}
                <Box sx={{minHeight:300}}>
                {renderInputField(
                  "Email*",
                  "email",
                  values.email,
                  "Enter your email",
                  handleChange,
                  touched.email,
                  errors.email
                )}
                { !location.state?.workspace && renderInputField(
                  "Workspace*",
                  "workspace",
                  values.workspace,
                  "Enter your workspace name",
                  handleChange,
                  touched.workspace,
                  errors.workspace
                )}
              </Box>
                <LoginButton
                  type={"submit"}
                  fullWidth
                  sx={{ textAlign: "center" }}
                >
                  {location?.state?.workspace ?"Send Workspace" : "Send Verification Code"}
                </LoginButton>
                <Box mt={3} sx={{ borderBottom: "1px solid lightGrey" }}></Box>
                <LoginButton
                  // type={"submit"}
                  onClick={() => navigate("/signin")}
                  // variant="outlined"
                  fullWidth
                  sx={{ textAlign: "center" }}
                >
                  Login workspace
                </LoginButton>
              </Container>
            </form>
          )}
        </Formik>
      </Card>
      <Alert severity={severity} handleClose={()=>setOpen(false)} message={message} open={open} />
    </Container>
    </Box>
  );
};

export default SignIn;
