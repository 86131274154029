import React from 'react';

const Trash = (({active})=>{
  return(
<svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.5625 6.5625L7.73438 25.3125C7.79004 26.3959 8.57812 27.1875 9.60938 27.1875H20.3906C21.426 27.1875 22.1994 26.3959 22.2656 25.3125L23.4375 6.5625" stroke={active ? "#FFF" :"#404B5A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.6875 6.5625H25.3125H4.6875Z" fill={active ? "#FFF" :"#404B5A"}/>
<path d="M4.6875 6.5625H25.3125" stroke={active ? "#FFF" :"#404B5A"} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M11.25 6.56251V4.21876C11.2495 4.03393 11.2855 3.85083 11.3559 3.67997C11.4264 3.50911 11.53 3.35387 11.6607 3.22318C11.7914 3.09249 11.9466 2.98893 12.1175 2.91845C12.2883 2.84797 12.4714 2.81196 12.6562 2.81251H17.3438C17.5286 2.81196 17.7117 2.84797 17.8825 2.91845C18.0534 2.98893 18.2086 3.09249 18.3393 3.22318C18.47 3.35387 18.5736 3.50911 18.6441 3.67997C18.7145 3.85083 18.7505 4.03393 18.75 4.21876V6.56251M15 10.3125V23.4375M10.7812 10.3125L11.25 23.4375M19.2188 10.3125L18.75 23.4375" stroke={active ? "#FFF" :"#404B5A"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
      );
})

export default Trash;
