import videojs from "video.js";
import "@videojs/themes/dist/fantasy/index.css";
import React, { useEffect, useRef } from "react";

const VideoJs = ({ options, themeName = "fantasy" }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    const player = playerRef.current;
    if (!player) {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      playerRef.current = videojs(videoElement, options);
    }
  }, [options, videoRef, playerRef]);
  return (
    <div data-vjs-player >
      <video
      style={{width:"50vw", height:"60vh"}}
        ref={videoRef}
        className={`video-js vjs-big-play-centered vjs-theme-${themeName}`}
      />
    </div>
  );
};

export default VideoJs;