import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Stack,
  Typography,
  CircularProgress,
  Alert,
  Box,
  Card,
} from "@mui/material";

import CreateDialog from "../../components/Dialog/CreateDialog";
import Cookies from "universal-cookie";
import TableComponent from "./TableComponent";
import { apiData } from "../../_services/home";
import SessionExpired from "../../components/Dialog/SessionExpired";
import {
  setFiles,
  setProgress,
  logoutSuccess,
  setSettingId,
  setCloudId,
} from "../../store/bugs";
import { connect } from "react-redux";
import DialogInfo from "./../../images/home/dialogInfo.svg";
import Alerts from "../../components/alert";
import AddBucketDialog from "./AddBucketDialog";
import Addcloud from "./../../images/home/addCloud.svg";
import Page from "../../components/page";
import { settingsData } from "../../_services/settings";
import RenameComponent from "../Folders/CreateFolder";
import { Button as MUIButton } from "@mui/material";
import Sidebar from "../../components/Toolbar/Sidebar";
import { crypto } from "../../components/Security/crypto";

const Button = styled.div`
  border-radius: var(--numbers-radius-sm);
  height: 32px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-7xs);
`;
const FrameParent = styled.div`
  position: absolute;
  top: 80px;
  width: 100%;
  height: 827px;
  overflow: hidden;
  color: var(--text-colors-text-secondary);
`;

const Home = (props) => {
  const cookies = new Cookies();
  const [severity, setSeverity] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("Important Notice");
  const [loading, setLoading] = useState(false);

  const [paste, setPaste] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [actionType, setActionType] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [item, setItem] = React.useState([]);
  const [bucketDialog, setBucketDialog] = useState(false);
  const [defaultBucket, setDefaultBucket] = useState();

  const [data, setData] = useState([]);
  useEffect(() => {
    GetUserSettings();
  }, []);

  const GetUserSettings = async () => {
    try {
      await settingsData.getAllUserSettings().then((res) => {
        console.log(res);
        const decreptedData = crypto?.decryption(res?.data?.data);
        if (res.status === 200 && res.data) {
          setBucketDialog(false);

          setData(decreptedData);
          console.log(decreptedData)
          let default_bucket = decreptedData.find(
            (item) => item.default_setting === true
          );
          setDefaultBucket(default_bucket);
          if (default_bucket) {
            cookies.set("bucketName", default_bucket.default_bucket);
            props.setSettingId({ settingId: default_bucket.id });
            props.setCloudId({ cloudId: default_bucket.cloud_provider_id });
          }
          if (decreptedData.length === 0) {
            setBucketDialog(true);
          }
        } else if (res.data.message === "Unauthorized!") {
          setSessionExpired(true);
        }
      });
    } catch (error) {
      console.log(error)
      setSeverity("error");
      setToastAlert(true);
      setToastMessage("Something went wrong");
    }
  };

  const handleDelete = (values) => {
    console.log(values);
    settingsData.deleteSetting(values).then((res) => {
      setPaste();
      setActionType();
      if (res.data.message === "Setting was deleted successfully!") {
        setSeverity("success");
        setToastAlert(true);
        setToastMessage("Account Successuflly deleted");
        GetUserSettings();
      } else {
        setSeverity("error");
        setToastAlert(true);
        setToastMessage("Failed to delete");
      }
    });
  };

  const handleAddBucket = async (values) => {
    setLoading(true);
    const data = {
      cloud_provider_id: values.cloudId,
      setting_name: values.accountName,
      cloud_settings:
        values.cloudId === 2
          ? JSON.parse(values.gcpKey)
          : values.cloudId === 3
          ? {
              connection_string: values.connectionString,
            }
          : {
              secret_key: values.secretKey,
              aws_key: values.accessKey,
            },
      region: values.cloudId === 2 ? null : values.region,
      default_bucket: values.bucketName,
    };

    try {
      apiData.addBucket(data).then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setSeverity("success");
          setToastAlert(true);
          setToastMessage(res.data.message);
          setBucketDialog(false);
          GetUserSettings();
        } else {
          setSeverity("error");
          setToastAlert(true);
          setToastMessage(res.data.message ? res.data.message : res.data.error);
        }
      });
    } catch {
      setLoading(false);
      setSeverity("error");
      setToastAlert(true);
      setToastMessage("Something went wrong");
    }
  };

  const updateSettings = (values) => {
    const data = {
      setting_name: values?.title,
      default_setting: values?.default_setting,
    };
    settingsData.updateSettings(data, values.id).then((res) => {
      console.log(res);
      if (res.data.status) {
        setSeverity("success");
        setToastAlert(true);
        setToastMessage(
          values.default_setting
            ? "Set as primary account"
            : "Renamed successfully"
        );
        setOpenDialog(false);
        GetUserSettings();
      } else {
        setSeverity("error");
        setToastAlert(true);
        setToastMessage(res.data.message ? res.data.message : res.data.error);
      }
    });
  };

  return (
    <Page>
      <Sidebar>
        <Box>
          <FrameParent>
            <Stack>
              {data.length > 0 && (
                <Card
                  sx={{
                    width: "300px",
                    height: "100px",
                    mx: 2,
                    backgroundColor: "#4c9eff",
                    p: 2,
                    borderRadius: "10px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", color: "#fff" }}>
                    Primary Account
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "#fff" }}>
                    <b>Account Name :</b> {defaultBucket?.setting_name}
                  </Typography>
                  <Typography sx={{ fontSize: "14px", color: "#fff" }}>
                    <b>Cloud Name :</b>{" "}
                    {defaultBucket?.cloud_provider.name ===
                    "Microsoft Azure Blob Storage"
                      ? "Microsoft Azure"
                      : defaultBucket?.cloud_provider.name}
                  </Typography>
                </Card>
              )}
            </Stack>
            <Box display={"flex"} justifyContent={"end"} mr={3}>
              <MUIButton
                onClick={() => setBucketDialog(true)}
                startIcon={<img src={Addcloud} width={25} alt="adduser" />}
                color="primary"
                variant="contained"
                sx={{
                  textTransform: "none",
                  backgroundColor: "#0075ff",
                  color: "#fff",
                  borderRadius: "8px",
                }}
              >
                Add Cloud
              </MUIButton>
            </Box>
            {loading ? (
              <>
                <Stack
                  spacing={2}
                  m={2}
                  direction="row"
                  style={{
                    position: "absolute",
                    top: "53%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <CircularProgress
                    // sx={{
                    //   color: theme === "default" ? "#4CB5F5" : "#4CB5F5",
                    // }}
                    size={20}
                  />
                  <Typography>Loading</Typography>
                </Stack>
              </>
            ) : (
              <TableComponent
                data={data}
                action={actionType}
                handleRename={updateSettings}
                setOpenDialog={setOpenDialog}
                setDialogTitle={setDialogTitle}
                handleDelete={handleDelete}
                setPaste={setPaste}
                handlePrimaryAccount={updateSettings}
                setItem={setItem}
                paste={paste}
              />
            )}
          </FrameParent>

          <CreateDialog
            open={sessionExpired}
            title={dialogTitle}
            children={<SessionExpired />}
          />

          <CreateDialog
            open={bucketDialog}
            size="sm"
            setLock={""}
            handleClose={() => setBucketDialog(false)}
            title="Add Cloud"
            children={
              <AddBucketDialog
                loading={loading}
                handleSubmit={handleAddBucket}
              />
            }
          />

          <Alerts
            severity={severity}
            handleClose={() => setToastAlert(false)}
            message={toastMessage}
            open={toastAlert}
          />
          {(actionType || paste) && (
            <Alert
              variant="alertToast"
              action={
                <Box display="flex">
                  <Button
                    disabled={!paste?.location}
                    color="inherit"
                    variant="save"
                    sx={{ mr: "65px", p: 1, textTransform: "capitalize" }}
                    onClick={() => {
                      if (paste?.type === "Delete") {
                        handleDelete(paste.location);
                      }
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    variant="cancel"
                    sx={{ p: 1, mr: 5 }}
                    onClick={() => {
                      setPaste();
                      setActionType();
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              }
              // style={{ }}
              icon={<img src={DialogInfo} alt="info" />}
            >
              <Typography variant="alertText">
                Are you sure want to delete this account?
              </Typography>
            </Alert>
          )}
        </Box>
        <CreateDialog
          open={openDialog}
          title={"Rename account name"}
          handleClose={setOpenDialog}
          children={
            <RenameComponent
              dialogTitle={"Rename account name"}
              close={setOpenDialog}
              data={{
                old_name: item?.setting_name,
                id: item?.id,
              }}
              formSubmit={updateSettings}
            />
          }
        />
      </Sidebar>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};
const dispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
  setFiles: (files) => dispatch(setFiles(files)),
  setProgress: (progress) => dispatch(setProgress(progress)),
  setSettingId: (settingId) => dispatch(setSettingId(settingId)),
  setCloudId: (cloudId) => dispatch(setCloudId(cloudId)),
});

export default connect(mapStateToProps, dispatchToProps)(Home);
