import axios from "axios";
import { helpers } from "../_helpers";
import { crypto } from "../components/Security/crypto";
const settings = require('../config');

const ApiEndpoint = settings.ApiEndPoint;

const apiEndpoint = ApiEndpoint;

export const loginApi = {
  registerNewUser,
  resendOtpforEmail,
  verifyEmail,
  login,
  forgotCredentials,
  resetPassword,
  registerWorkspace,
  changePassword
};




async function registerNewUser(userData) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const encryptedData =  crypto.encryption(userData)
  try {
    const data = await axios.post(
      `${apiEndpoint}/register `,
      encryptedData,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}


async function login(userData) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const encryptedData =  crypto.encryption(userData)
  try {
    const data = await axios.post(
      `${apiEndpoint}/login`,
      encryptedData,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}

async function forgotCredentials(userData,credentials) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const data = await axios.post(
      `${apiEndpoint}/${credentials}`,
      userData,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}

async function resendOtpforEmail(email) {
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const encryptedData =  crypto.encryption(email)
    try {
      const data = await axios.post(
        `${apiEndpoint}/resend_otp `,
        encryptedData,
        requestOptions
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async function verifyEmail(otp) {
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const encryptedData = crypto.encryption(otp)
    try {
      const data = await axios.post(
        `${apiEndpoint}/verify_otp `,
        encryptedData,
        requestOptions
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async function resetPassword(otp) {
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const encryptedData = crypto.encryption(otp)
    try {
      const data = await axios.post(
        `${apiEndpoint}/reset_password `,
        encryptedData,
        requestOptions
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async function registerWorkspace(payload) {
    helpers.setHeader();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const encryptedData = crypto.encryption(payload)
    try {
      const data = await axios.post(
        `${apiEndpoint}/workspace `,
        encryptedData,
        requestOptions
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }

  

  async function changePassword(otp) {
    helpers.setHeader();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const encryptedData = crypto.encryption(otp)
    try {
      const data = await axios.post(
        `${apiEndpoint}/change_password `,
        encryptedData,
        requestOptions
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }





