import  React from "react";
import Upload from "./../../images/uploadFile.svg";
import {
  Box,
  IconButton,
  Button,
  Typography,
  Stack,
  Grid,
  styled
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const  UploadDialog =({file,theme,progressMap,setFile,UploadDocuments}) =>{
  const [error, setError] = React.useState(false);


  React.useEffect(() => {
    if (file.length > 10) {
      setError(true);
    } else {
      setError(false);
    }
  }, [file]);



  async function filechoose(event) {
    const files = event.target.files;
    if (files.length > 10) {
      alert("You can select up to 10 files at a time.");
      return;
    }else{
    UploadDocuments(files)
    let array1 = Array.from(files);
    setFile([...file, ...array1]);
  }
  }

  const handleDragEnter = (event) => {
    event.preventDefault();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 10) {
      alert("You can select up to 10 files at a time.");
      return;
    }else{
    handleFiles(files);
    }
  };

  const handleFiles = (files) => {
    const fileList = Array.from(files);
    setFile(fileList);
    UploadDocuments(fileList)
  };


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#4CB5F5",
  },
}));
  return (
    <>
      <Box
        spacing={1}
        sx={{
          textAlign: "center",
          alignItems: "center",
          minHeight: "170px",
          mt: 2,
        }}
      >
        <Grid container columnSpacing={3}>
          <Grid item xs={6} sx={{ margin: "auto" }}>
            <form>
              <IconButton    
                disabled={file.length>=1}
                disableRipple
                component="label"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                }}
                
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <input
                  hidden
                  type="file"
                  multiple
                  onChange={filechoose} 
                />
                <img
                  src={Upload}
                  style={{ width: 50, height: 50 }}
                  alt=""
                ></img>
                <Typography
                  variant="folderSize"
                  mt={1}
                  sx={{ fontSize: "14px" }}
                >
                   Drag and drop your files
                </Typography>
                <Typography variant="folderSize" my={0.5}>
                  Or
                </Typography>
                <Button component="span" variant="save">Upload</Button>
              </IconButton>
            </form>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ backgroundColor: "#fafafa", minHeight: "300px" }}
            mb={2}
          >
            <Typography
              sx={{
                borderBottom: "1px solid #ADAAAA",
                textAlign: "left",
                color: "041527",
                fontWeight: "bold",
                fontSize: "14px",
                pb: 1,
                mt: -1,
              }}
            >
              Uploading
            </Typography>
            {file.map((item, index) => (
              <div
              key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  border: "0.5px solid #E3E3E3",
                  alignItems: "center",
                  padding: "10px",
                  marginTop: "8px",
                  borderRadius: "5px",
                }}
              >
                <Stack style={{width:'100%'}}  mr={3}>
                <div style={{ display: "flex",justifyContent:'space-between' }}>
                <Typography
                  key={index}
                  variant="folderSize"
                  sx={{

                    textAlign: "left",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width:'30ch',
                    textOverflow:"ellipsis",
                  }}
                >
                  {item.name}
                </Typography>
                  <Typography key={index} variant="folderSize">
                    {`${parseFloat(item.size / 1024 / 1024).toFixed(2)}MB`}
                  </Typography>
                </div>
                <BorderLinearProgress variant="determinate" value={progressMap[item.name] || 0} />
                </Stack>

                {progressMap[item.name] ===100 ? 
                <CheckCircleIcon style={{ color: "#3AB874", width:'18px' }}/> :
                 null}
              </div>
            ))}
            {error ? (
              <Typography variant="folderSize" sx={{ color: "red" }}>
                Max 10 files
              </Typography>
            ) : null}
          </Grid>
        </Grid>
        
      </Box>
    </>

  );
}

export default UploadDialog;