import { createAction, createReducer } from '@reduxjs/toolkit'

//action creater
export const bugAdded = createAction("bugAdded")

export const bugRemoved = createAction("bugRemoved");
export const bugResolved = createAction("bugResolved");
const theme = localStorage.getItem('theme')
const stete = {
    token:null,    
    auth:null,
    bugs:[],
    prefix:null,
    files:[],
    progress:[],
    settingId:null,
    cloudId:null,
    password:null,
    theme:theme ? theme : 'default'
}


export const setToken = createAction("setToken")
export const loginSuccess = createAction("loginSuccess")
export const logoutSuccess = createAction("logoutSuccess") 
export const switchRole = createAction("switchRole")
export const setPrefix = createAction("setPrefix")
export const setTheme = createAction("setTheme")
export const setFiles = createAction("setFiles")
export const setProgress = createAction("setProgress")
export const setSettingId = createAction("setSettingId")
export const setCloudId = createAction("setCloudId")
export const setPassword = createAction("setPassword")







let lastId = 0;
export default createReducer(stete,{
    bugAdded:(state, action) =>{
        state.bugs.push({
            id:++lastId,
            description:action.payload.description,
            resolved:false
        })
    },

    setToken:(state, action) =>{
        state.token=action.payload.token
    },
    setFiles:(state, action) =>{
        state.files=action.payload.files
    },
    setProgress:(state, action) =>{
        state.progress=action.payload.progress
    },
    setSettingId:(state, action) =>{
        state.settingId=action.payload.settingId
    },
    setCloudId:(state, action) =>{
        state.cloudId=action.payload.cloudId
    },
    setPassword:(state, action) =>{
        state.password=action.payload.password
    },
    setPrefix:(state, action) =>{
        state.prefix=action.payload.prefix
    },

    setTheme:(state, action) =>{
        state.theme=action.payload.theme
    },

    loginSuccess:(state, action) =>{
        state.auth=action.payload.auth
    },
    logoutSuccess:(state, action) =>{
        state.auth.loggedIn=false;
        state.auth.user=null;
        state.token=null
        state.prefix=null
        state.settingId=null
        state.cloudId=null
        state.password=null
        state.theme=theme ? theme :'default'
    },


})


