import React from 'react'
import { styled } from '@mui/material/styles';
import {  InputLabel, DialogActions,Button, InputBase ,FormHelperText} from '@mui/material';
import { Formik } from "formik";
import * as Yup from 'yup';


const PREFIX = 'CreateFolder';

const classes = {
  inputbase: `${PREFIX}-inputbase`,
  textArea: `${PREFIX}-textArea`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.inputbase}`]: {
      border: '1px solid #D9D9D9',
      padding: '5px 12px',
      width: '100%',
      borderRadius: '2px',
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: "400",
      "&::placeholder": {
          color: "#00000040",
      },
  },

  [`& .${classes.textArea}`]: {
      border: '1px solid #D9D9D9',
      padding: '5px 12px',
      width: '100%',
      borderRadius: '2px',
      maxWidth: '100%',
      minWidth: '100%',
      "&:focus": {
          border: '1px solid #D9D9D9 !important',
      },
      "&::placeholder": {
          color: "#00000040",
      },
  }
}));

const CreateFolder = (props) => {

    const handleCancel = () => {
        props.close(false);
      };
    return (
      <Root>
      <Formik
      initialValues={{
        title: props?.data ? props?.data?.old_name : '',
        location:props?.data ? props?.data?.location : '',
        id:props?.data ? props?.data?.id : '',
      }}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        title: Yup.string().required(`Please enter ${ props.dialogTitle === 'Create New File' ? "file name" : props.fileOpen ? "password" :"folder name"}`),
      })}
      onSubmit={(values) => {
        props.close(false)
        props.formSubmit(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        submitCount
      }) => (
        <form noValidate onSubmit={handleSubmit}>
      <div style={{minHeight:'100px'}}>
          <InputLabel htmlFor="bootstrap-input" error={submitCount>0 && touched.title && errors.title}>
              {(props.lock?.name || props?.fileOpen) ? "Password" : props.dialogTitle==="Create Folder" ? "Folder Name": (props.dialogTitle === 'Create New File' || props.data?.type !=='folder' )?  "File Name" : props.dialogTitle === "Rename account name" ? "Account name": props.fileOpen ? "Enter  password"  :"Folder Name"}
          </InputLabel>
          <InputBase 
          style={{border:submitCount>0 && touched.title && errors.title &&'1px solid red'}} 
          // error={}
          name="title"
          type={(props.lock?.name||props.fileOpen) ? "password": "text"}
          value={values.title}
          onBlur={handleBlur}
          onChange={handleChange}
          variant="MyCustomInput1" placeholder='' size="small" className={classes.inputbase} />
       {submitCount>0 && touched.title && errors.title && (
                    <FormHelperText error>
                      {errors.title}
                    </FormHelperText>
                  )}
      </div>
      <DialogActions>
            <Button
              variant="save"
              className={classes.contained}
              type="submit"
              // onClick={handleCancel}
            >
             { props.dialogTitle === 'Create New File' ? 'Create' : props.fileOpen ? "Open" : 'Save'}
            </Button>
            <Button variant="cancel" onClick={handleCancel}>
              Cancel
            </Button>
          </DialogActions>
      </form>
    )}
  </Formik>
      </Root>
    );
}

export default CreateFolder