import React from 'react'

import {Box,List, CssBaseline, Drawer} from '@mui/material';
import Logo from './../../images/home/logo.png'
import Favourite from "../../images/NavIcons/Favourite";
import FileCloud from "../../images/NavIcons/Filecloud";
import Trash from "./../../images/NavIcons/trash";
import Folder from "./../../images/NavIcons/folder";
import styled from 'styled-components';
import { useNavigate,useLocation } from 'react-router-dom';
import { ExitToApp } from '@mui/icons-material';
import {  logoutSuccess,setFiles } from "../../store/bugs";
import { connect } from "react-redux";
import Cookies from 'universal-cookie';

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;

const Text1 = styled.div`
  position: relative;
  font-weight: 500;
  font-family:Inter;
  font-size:15px;
`;


const IconHugearrowChevronDropdo1 = styled.img`
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
`;
const Inner = styled.div`
  align-self: stretch;
  border-radius: var(--numbers-radius-base);
  height: 40px;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-7xs) var(--padding-xs);
  box-sizing: border-box;
  :hover {
    background-color: lightGrey;
  }
`;
const BaseSidebarMenu = styled.div`
  align-self: stretch;
  height: 44px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-11xs) var(--padding-5xs);
  box-sizing: border-box;
  hover: {
    background-color: grey;
  }
`;

const ToolbarComponent = (props) => {   
    const drawerWidth = 240;
    const navigate= useNavigate();
    const cookies = new Cookies();
    const location = useLocation()

    const sideMenu = [
        // {
        //   name: "Dashboard",
        //   icon: <Dashboard />,
        //   activeIcon: <Dashboard active={true} />,
        //   href: "/home",
        // },
        {
          name: "Integrations",
          icon: <FileCloud />,
          activeIcon: <FileCloud active={true} />,
          href: "/home",
        },
        // {
        //   name: "Users",
        //   icon: <Users />,
        //   activeIcon: <Users active={true} />,
        //   href: "/users",
        // },
        {
          name: "Folders",
          icon: <Folder />,
          activeIcon: <Folder active={true} />,
          href: "/folders",
        },
        {
          name: "Favourites",
          icon: <Favourite />,
          activeIcon: <Favourite active={true} />,
          href: "/favourites",
        },
        {
          name: "Trash",
          icon: <Trash />,
          activeIcon: <Trash active={true} />,
          href: "/trash",
        }
      ];

      const logout = () => {
        props.logoutSuccess();
        cookies.remove("token");
        cookies.remove("bucketName");
        navigate("/signin", { replace: true });
      };

    

  return (
    <Box>
        <CssBaseline>
    
  <Drawer
  variant="permanent"
  sx={{
    width: drawerWidth,
    position:'relative',
    flexShrink: 0,
    [`& .MuiDrawer-paper`]: { width: drawerWidth,background:'#EEF4FC',borderRight:'none', boxSizing: 'border-box' },
  }}
>
  <Box sx={{ overflow: 'auto',}}>
    <List sx={{ height:'calc(100vh - 100px)' , position:'relative'}}>
      <Box sx={{margin:'1rem'}}>
    <img src={Logo} width={180}  alt="" />
      </Box>

    {sideMenu.map((item, index) => {
          return (
            location.pathname === item.href ?
              (<BaseSidebarMenu key={index}>
              <Inner
                style={{
                  borderRadius:  "8px",
                  backgroundColor:  "#0075ff",
                  color:  "#fff",
                }}
                onClick={() => {
                  localStorage.setItem('path','')
                  navigate(item.href)}}
                href={item.href}
              >
                <Content>
                  {item.activeIcon}
                  <Text1>{item.name}</Text1>
                </Content>
                <IconHugearrowChevronDropdo1 alt="" />
              </Inner>
            </BaseSidebarMenu>):(
              <BaseSidebarMenu key={index}>
              <Inner
                onClick={() => {navigate(item.href)
                  localStorage.setItem('path','')
                }}
                href={item.href}
              >
                <Content>
                  {item.icon}
                  <Text1>{item.name}</Text1>
                </Content>
                <IconHugearrowChevronDropdo1 alt="" />
              </Inner>
            </BaseSidebarMenu>

            )
          );
        })}
         <BaseSidebarMenu style={{position:"absolute",bottom:0, width:'100%'}}>
              <Inner
                onClick={() => logout()}
              >
                <Content>
                  <ExitToApp style={{width:20,color:'#404B5A'}}/>
                  <Text1>Logout</Text1>
                </Content>
                <IconHugearrowChevronDropdo1 alt="" />
              </Inner>
            </BaseSidebarMenu>
    </List>
  </Box>
</Drawer>
</CssBaseline>
</Box>
  )
}

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};
const dispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
  setFiles: (files) => dispatch(setFiles(files)),
});

export default connect(mapStateToProps, dispatchToProps)(ToolbarComponent);

