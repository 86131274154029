import { CloseOutlined } from '@mui/icons-material'
import { Box, Typography, Grid, Grow, IconButton } from '@mui/material'
import StyledBadge from './../../components/Badge/index.jsx'
import Folder from "./../../images/home/folder.jsx";
import { FileType } from "../Function";
import React from 'react'

const FilePreview = ({ data, setClick }) => {
  const fileType = (type) => {
    return FileType.getFileType(type);
  };
  const renderGrid = ((label, name) => {
    return (
      <>
        <Grid item xs={4} md={4} lg={4} mt={0.5}>
          <Typography variant="settingLable">{label} :</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={8} mt={0.5} classes="label">
          <Typography variant="settingLable">
            {name}
          </Typography>
        </Grid>
      </>
    )
  })
  return (
    <Grid item xs={2.95}  sx={{border:'1px solid lightGrey',borderRadius:'10px', margin:'8px 0'  }}>
      {!data ? <Typography m={2}>Loading...</Typography> :
        <Grow in={true}>
          <Box sx={{ width: '100%', m: 1, p: 3}}>
            <IconButton sx={{ float: 'right', mt:-2 }}>
            <CloseOutlined onClick={() => setClick(false)}  />
            </IconButton>
            <Box
              display="flex"
              mt={3}
              sx={{ minHeight: "150px" }}
            >{
                data.lock ? (
                  <StyledBadge preview={true} >
                    {(data?.type === "png" || data?.type === "jpeg" || data?.type === "jpg" || data?.type === "webp") && data.url !== "" ?
                      <img style={{ width: 100, height: 'auto', aspectRatio: 3 / 2, objectFit: 'contain' }} src={data?.url} alt="" /> : (
                        data?.type === "File Folder" ? <Folder theme={""} width={130} /> : <img
                          src={fileType(data?.type)}
                          width="100"
                          alt=""
                        />
                      )}
                  </StyledBadge>
                ) : (
                  (data?.type === "png" || data?.type === "jpeg" || data?.type === "jpg" || data?.type === "webp") && data.url !== "" ?
                    <img style={{ width: 100, height: 'auto', aspectRatio: 3 / 2, objectFit: 'contain' }} src={data?.url} alt="" /> : (
                      data?.type === "File Folder" ? <Folder theme={""} width={130} /> : <img
                        src={fileType(data?.type)}
                        width="100"
                        alt=""
                      />
                    ))}
            </Box>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography variant="previewHeader" >
                Details
                </Typography></Grid>
              {renderGrid('Name', data?.name)}
              {renderGrid('Type', data?.type?.toUpperCase())}
              {renderGrid('Size', data?.size)}
            </Grid>
          </Box>
        </Grow>}
    </Grid>
  )
}

export default FilePreview