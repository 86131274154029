import { Box } from "@mui/material";
import React from "react";
import TrashTable from "./TrashTable";
import styled from "styled-components";
import Sidebar from "../../components/Toolbar/Sidebar";
const FrameDiv = styled.div`
position: absolute;
top: 100px;
width: 100%;
height: 827px;
overflow: hidden;
color: var(--text-colors-text-secondary);
`;
const Trash = () => {
  return (
    <Sidebar>
      <FrameDiv>
        <Box display={"flex"} justifyContent={"end"} mr={3}>
          {/* <Button
          onClick={()=>setOpen(!open)}
            startIcon={<Delete sx={{color:'#878787'}}/>}
            color="primary"
            variant="contained"
            sx={{ textTransform: "none" }}
          >
            Delete
          </Button> */}
        </Box>
        <TrashTable selectableRows={false} theme={'default'} />
      </FrameDiv>
    </Sidebar>
  );
};

export default Trash;
